<template>
    <div class="info">
        <topHeader ind="7"></topHeader>
        <pageContent title="消息通知" :total="total" :list="infoList" @getArticleList="getInfo"/>
        <foot></foot>
    </div>
</template>

<script>
    import axios from '../http/axios';
    import pageContent from '../comm/pageContent.vue';
    export default {
        data(){
            return{
                infoList:[],
                total:null
            }
        },
        components:{
            pageContent
        },
        mounted(){
            this.getInfo()
        },
        methods:{
            getInfo(page=1,page_number=10){
                axios.post('notice/list',{page,page_number}).then(res=>{
                    if(res.err_code == 200){
                        this.infoList = res.data.data
                        this.total = res.data.total
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>

</style>